import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Img from "gatsby-image"
import { Link } from "gatsby"
import SEO from "../components/seo"

export default class PortfolioIndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressWpPortfolio

    return (
      <Layout sidebar="hidden">
         <SEO title={`Portfolio | Art Specialists`} />

        <div id="content">
            <div id="page-content" className="page-content--no-sidebar">
                <h1>Portfolio</h1>
                <div className="portfolio-columns">
                    {posts.map(item => (
                        <div key={item.node.id} className="portfolio-item">
                           <Link to={`/portfolio/${item.node.slug}/`}>
                              <Img fluid={item.node.featured_media.localFile.childImageSharp.fluid} alt={item.node.title} />
                              <span className="portfolio-item__title">{item.node.title}</span>
                           </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
      </Layout>
    )
  }
}

PortfolioIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query PortfolioIndexQuery($limit: Int!, $skip: Int!) {
    allWordpressWpPortfolio(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          title
          content
          featured_media {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 345, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    }
  }
`